.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-container {
  width: 80%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fff;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px #d3d3d3;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (ma-width: 900px) {
    margin-top: 2rem;
  }
}

// LinkedInBadge.scss
.linkedin-badge {
  display: inline-block;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%; // Ensure it takes full width on mobile
  max-width: 500px; // Limit maximum width for larger screens

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .badge-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    padding: 16px;
  }

  .badge-content {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%; // Ensure it takes full width
  }

  .profile-photo-container {
    width: 80px; // Adjusted width
    height: 80px; // Adjusted height
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #0077b5;
    position: relative;
    flex-shrink: 0; // Prevent the photo from shrinking on smaller screens

    .profile-photo {
      width: 100%;
      height: 100%;
      object-fit: contain; // Ensures the image fits within the container
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .profile-info {
    text-align: left;
    flex-grow: 1; // Allow the info section to grow and take available space

    .profile-calltoacation {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      font-size: 12px;
      color: #999;
    }

    .profile-name {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 600;
      color: #333;
    }

    .profile-headline {
      margin: 4px 0;
      font-size: 0.875rem;
      color: #666;
    }

    .profile-location {
      margin: 0;
      font-size: 0.75rem;
      color: #999;
    }
  }

  .linkedin-logo {
    margin-left: auto;
    flex-shrink: 0; // Prevent the logo from shrinking

    svg {
      width: 24px;
      height: 24px;
      fill: #0077b5;
    }
  }

  // Mobile Breakpoints
  @media (max-width: 768px) {
    .badge-link {
      padding: 12px; // Reduce padding for smaller screens
    }

    .profile-photo-container {
      width: 120px; // Smaller profile photo width
      height: 60px; // Smaller profile photo height
    }

    .profile-info {
      .profile-calltoacation {
        font-size: 10px; // Smaller font size for call-to-action
      }

      .profile-name {
        font-size: 1rem; // Smaller font size for name
      }

      .profile-headline {
        font-size: 0.75rem; // Smaller font size for headline
      }

      .profile-location {
        font-size: 0.65rem; // Smaller font size for location
      }
    }

    .linkedin-logo {
      svg {
        width: 20px; // Smaller LinkedIn logo
        height: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    .badge-link {
      padding: 8px; // Further reduce padding for very small screens
    }

    .profile-photo-container {
      width: 100px; // Even smaller profile photo width
      height: 50px; // Even smaller profile photo height
    }

    .profile-info {
      .profile-calltoacation {
        font-size: 9px; // Smaller font size for call-to-action
      }

      .profile-name {
        font-size: 0.875rem; // Smaller font size for name
      }

      .profile-headline {
        font-size: 0.7rem; // Smaller font size for headline
      }

      .profile-location {
        font-size: 0.6rem; // Smaller font size for location
      }
    }

    .linkedin-logo {
      svg {
        width: 18px; // Smaller LinkedIn logo
        height: 18px;
      }
    }
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 1rem 0;
}

.app__skills-exp-works {
  flex: 1;

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 1rem;

    cursor: pointer;

    h4 {
      font-weight: 500;
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}
