.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 110px;
    height: 48px;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .app__navbar-link {
    margin: 0 1rem;
    cursor: pointer;
    position: relative; // Required for pseudo-element positioning

    a {
      color: var(--gray-color);
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    // Pink dot on hover
    &::after {
      content: "";
      position: absolute;
      bottom: -5px; // Position below the link
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 6px;
      background-color: #ff69b4; // Pink dot color
      border-radius: 50%;
      opacity: 0; // Initially hidden
      transition: opacity 0.3s ease-in-out;
    }

    &:hover::after {
      opacity: 1; // Show the dot on hover
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
    cursor: pointer;
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.app__navbar-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  padding: 1rem;
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  background: url(../../assets/bgWhite.png);
  background-color: var(--white-color);
  background-size: cover;
  background-repeat: repeat;
  box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

  svg {
    width: 35px;
    height: 35px;
    color: var(--secondary-color);
    margin: 0.5rem 1rem;
    cursor: pointer;
  }

  .app__navbar-mobile-links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    div {
      margin: 1rem;
      position: relative; // Required for pseudo-element positioning

      a {
        color: var(--gray-color);
        text-decoration: none;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 400;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }

      // Pink dot on hover for mobile links
      &::after {
        content: "";
        position: absolute;
        bottom: -5px; // Position below the link
        left: 50%;
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        background-color: #ff69b4; // Pink dot color
        border-radius: 50%;
        opacity: 0; // Initially hidden
        transition: opacity 0.3s ease-in-out;
      }

      &:hover::after {
        opacity: 1; // Show the dot on hover
      }
    }
  }
}
