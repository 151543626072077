// Variables
$gray: #ccc;
$pink: #ff69b4;

// Keyframes for floating animation
@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(20px) translateX(40px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

// Mobile Breakpoint
@media (max-width: 767px) {
  @keyframes float {
    0% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(20px) translateX(0px);
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
}

// Hero Section
.hero__area {
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  background-color: #000000;

  .logo_container {
    display: flex;
    justify-content: start;
    padding-left: 100px;

    // Mobile Breakpoint
    @media (max-width: 767px) {
      justify-content: center;
      padding-left: 0;
      margin-bottom: 20px;
    }
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin: -15px;

      .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 15px;

        .hero__content {
          text-align: center;
          position: relative;
          z-index: 1;

          a {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 18px;
            color: #c1c1c1;
            text-decoration: none;

            span {
              margin-left: 10px;
            }

            // Mobile Breakpoint
            @media (max-width: 767px) {
              font-size: 16px;
            }
          }

          .hero__title-wrapper {
            margin-bottom: 40px;

            .hero__title {
              color: #fefefe;
              margin-bottom: 40px;
              font-weight: 800;
              font-size: 100px;
              line-height: 1;

              // Mobile Breakpoint
              @media (max-width: 767px) {
                font-size: 48px;
                margin-bottom: 20px;
              }
            }

            .hero__sub-title {
              font-size: 18px;
              color: #d9d9d9;
              line-height: 1.6;

              span {
                font-weight: bold;
                color: $pink;
              }

              // Mobile Breakpoint
              @media (max-width: 767px) {
                font-size: 16px;
                padding: 0 15px;
              }
            }
          }

          .btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;

            // Mobile Breakpoint
            @media (max-width: 767px) {
              margin-top: 30px;
            }

            .circle-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 150px;
              height: 150px;
              border: 1px solid $gray;
              border-radius: 50%;
              text-decoration: none;
              color: $pink;
              font-size: 16px;
              font-weight: 600;
              text-transform: capitalize;
              position: relative;
              overflow: hidden;
              transition: color 0.3s ease;

              // Mobile Breakpoint
              @media (max-width: 767px) {
                width: 120px;
                height: 120px;
                font-size: 14px;
              }

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $pink;
                transition: transform 0.3s ease;
                z-index: -1;
                transform: translate(
                  var(--hover-x, 100%),
                  var(--hover-y, 100%)
                );
                border-radius: 50%;
              }

              &:hover {
                color: #fff;
                &::before {
                  transform: translate(0, 0);
                }
              }
            }
          }

          .experience {
            margin-top: 60px;

            // Mobile Breakpoint
            @media (max-width: 767px) {
              margin-top: 40px;
            }

            .title {
              font-size: 48px;
              font-weight: 700;
              color: #c6c5c5;
              margin-bottom: 10px;

              // Mobile Breakpoint
              @media (max-width: 767px) {
                font-size: 36px;
              }
            }

            p {
              font-size: 18px;
              color: #c6c5c5;

              // Mobile Breakpoint
              @media (max-width: 767px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .hero1_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3;
    animation: float 10s ease-in-out infinite;

    // Mobile Breakpoint
    @media (max-width: 767px) {
      animation: float 15s ease-in-out infinite; // Slower animation for mobile
    }
  }

  // Global Mobile Breakpoint Adjustments
  @media (max-width: 767px) {
    padding: 60px 0;
  }
}
